import { readAsArrayBuffer } from './asyncReader.js';
import { fetchFont, getAsset } from './prepareAssets';
import { noop } from './helper.js';

export async function save(pdfFile, objects, name) {
  const PDFLib = await getAsset('PDFLib');
  const download = await getAsset('download');
  const makeTextPDF = await getAsset('makeTextPDF');

  const urlParams = new URLSearchParams(window.location.search);

  let siteType = urlParams.get("st");

  let docURL = "http://localhost:8888/";
  let siteHost = "http://localhost:8888/";

  if (siteType == "P") {
    docURL = "https://dts.depedcar.ph/";
    siteHost = "https://dts.depedcar.ph/";
  } else if (siteType == "T") {
    docURL = "https://training.dts.depedcar.ph/";
    siteHost = "https://training.dts.depedcar.ph/";
  }

  let documentId = urlParams.get("doc");
  let attachmentId = urlParams.get("ai");
  if (attachmentId !== undefined && attachmentId.length > 0) {
    docURL = docURL + "attachment/save?id=" + attachmentId;
  }

  let token = urlParams.get("tid");
  if (token !== undefined && token.length > 0) {
    docURL = docURL + "&tid=" + token;
  }

  const serverURL = docURL;

  let pdfDoc;
  try {
    pdfDoc = await PDFLib.PDFDocument.load(await readAsArrayBuffer(pdfFile));
  } catch (e) {
    console.log('Failed to load PDF.');
    throw e;
  }
  const pagesProcesses = pdfDoc.getPages().map(async (page, pageIndex) => {
    const pageObjects = objects[pageIndex];
    // 'y' starts from bottom in PDFLib, use this to calculate y
    const pageHeight = page.getHeight();
    const embedProcesses = pageObjects.map(async (object) => {
      if (object.type === 'image') {
        let { file, x, y, width, height } = object;
        let img;
        try {
          if (file.type === 'image/jpeg') {
            img = await pdfDoc.embedJpg(await readAsArrayBuffer(file));
          } else {
            img = await pdfDoc.embedPng(await readAsArrayBuffer(file));
          }
          return () =>
            page.drawImage(img, {
              x,
              y: pageHeight - y - height,
              width,
              height,
            });
        } catch (e) {
          console.log('Failed to embed image.', e);
          return noop;
        }
      } else if (object.type === 'text') {
        let { x, y, lines, lineHeight, size, fontFamily, width } = object;
        const height = size * lineHeight * lines.length;
        const font = await fetchFont(fontFamily);
        const [textPage] = await pdfDoc.embedPdf(
          await makeTextPDF({
            lines,
            fontSize: size,
            lineHeight,
            width,
            height,
            font: font.buffer || fontFamily, // built-in font family
            dy: font.correction(size, lineHeight),
          }),
        );
        return () =>
          page.drawPage(textPage, {
            width,
            height,
            x,
            y: pageHeight - y - height,
          });
      } else if (object.type === 'drawing') {
        let { x, y, path, scale } = object;
        const {
          pushGraphicsState,
          setLineCap,
          popGraphicsState,
          setLineJoin,
          LineCapStyle,
          LineJoinStyle,
        } = PDFLib;
        return () => {
          page.pushOperators(
            pushGraphicsState(),
            setLineCap(LineCapStyle.Round),
            setLineJoin(LineJoinStyle.Round),
          );
          page.drawSvgPath(path, {
            borderWidth: 1,
            scale,
            x,
            y: pageHeight - y,
          });
          page.pushOperators(popGraphicsState());
        };
      }
    });
    // embed objects in order
    const drawProcesses = await Promise.all(embedProcesses);
    drawProcesses.forEach((p) => p());
  });
  await Promise.all(pagesProcesses);
  try {
    const pdfBytes = await pdfDoc.save();
    // download(pdfBytes, name, 'application/pdf');

    // Prepare headers for the request
    const headers = {
      'Content-Type': 'application/pdf', // Set the appropriate content type
    };
    // Make the POST request using fetch
    const response = await fetch(serverURL, {
      method: 'POST',
      headers,
      body: pdfBytes, // The PDF binary data
    });

    // Check the response status
    if (response.ok) {
      console.log('PDF successfully sent to the server.');
      setTimeout(() => {
        window.close();
        let returnTo = siteHost + "document/view?id=" + documentId;
        window.open(returnTo);
      });
    } else {
      console.error('Failed to send PDF to the server:', response.status, response.statusText);
    }
  } catch (e) {
    console.log('Failed to save PDF.');
    throw e;
  }
}
